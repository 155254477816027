import Glide from '@glidejs/glide';
import { Controls } from '@glidejs/glide/dist/glide.modular.esm';

import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import browser from '../../helpers/browser';
import dom from '../../wrapper/DomWrapper';

const SPEED_FAST = 100;
const SPEED_SLOW = 500;

// eslint-disable-next-line func-names
const EventConnection = function (GlideOb, Components, Events) {
  const { arrows, dots } = GlideOb.settings;

  if (!arrows && !dots) return {};

  let timer;
  let transitionTimer;

  Events.on('run', () => {
    Components.Controls.setActive();
    GlideOb.enable();
    const { length } = Components.Sizes;

    const { index, settings: { animationDuration } } = GlideOb;

    if (animationDuration !== SPEED_FAST) {
      // eslint-disable-next-line no-param-reassign
      GlideOb.settings.animationDuration = SPEED_FAST;
    } else if (index > 0 && index < length - 1) {
      clearTimeout(transitionTimer);
    }

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      GlideOb.settings.animationDuration = SPEED_SLOW;
    }, SPEED_SLOW);
  });

  if (GlideOb.isType('slider')) return {};

  // eslint-disable-next-line no-param-reassign
  Events.events.move = Events.events.move.slice(1);

  // eslint-disable-next-line no-param-reassign
  Components.Transition.after = function after(callback, name) {
    const isTranslate = name && name === 'Translate';

    if (!isTranslate) {
      setTimeout(() => {
        callback();
      }, this.duration);
    } else {
      transitionTimer = setTimeout(() => {
        callback();
      }, this.duration);
    }
  };

  Events.on('move', (context) => {
    const gap = Components.Gaps.value;
    const { length } = Components.Sizes;
    const width = Components.Sizes.slideWidth;

    if (GlideOb.isType('carousel') && Components.Run.isOffset('<')) {
      Components.Transition.after(() => {
        Events.emit('translate.jump');

        Components.Translate.set(width * (length - 1));
      }, 'Translate');

      return Components.Translate.set(-width - gap * length);
    }

    if (GlideOb.isType('carousel') && Components.Run.isOffset('>')) {
      Components.Transition.after(() => {
        Events.emit('translate.jump');

        Components.Translate.set(0);
      }, 'Translate');

      return Components.Translate.set(width * length + gap * length);
    }

    return Components.Translate.set(context.movement);
  });

  return {};
};

const CustomControls = (hash) => (GlideOb, Components, Events) => {
  const NAV_SELECTOR = `.slider${hash} > [data-glide-el="controls[nav]"]`;
  const CONTROLS_SELECTOR = `.slider${hash} > [data-glide-el^="controls"]`;

  const nativeControls = isFunction(Controls) ? Controls(GlideOb, Components, Events) : {};

  // SP-172972
  nativeControls.mount = function mount() {
    /**
     * Collection of navigation HTML elements.
     *
     * @private
     * @type {HTMLCollection}
     */
    // eslint-disable-next-line no-underscore-dangle
    this._n = Components.Html.root.querySelectorAll(NAV_SELECTOR);

    /**
     * Collection of controls HTML elements.
     *
     * @private
     * @type {HTMLCollection}
     */
    // eslint-disable-next-line no-underscore-dangle
    this._c = Components.Html.root.querySelectorAll(CONTROLS_SELECTOR);

    if (isFunction(this.addBindings)) this.addBindings();
  };

  return nativeControls;
};

const GlideSlider = (hash, element, options) => {
  const elSlides = dom.getCollection('.slick-slide', element);
  const elPrevArrow = dom.getElement('.slick-prev', element);
  const elNextArrow = dom.getElement('.slick-next', element);
  const elBg = dom.getCollection('.slide_bg', element);
  let sliderClassName = `.slider${hash}`;

  const { style: { marginTop } } = element;

  if (marginTop && !isEmpty(elBg)) {
    elBg.forEach((el) => {
      dom.updateStyle(el, {
        top: marginTop,
      });
    });
  }

  const sliderCount = elSlides.length;

  const { arrows, type } = options;

  const settings = {
    rewind: false,
    classes: {
      activeNav: 'slick-active',
      disabledArrow: 'slick-disabled',
      cloneSlide: 'slick-cloned',
    },
    gap: 0,
    keyboard: false,
    ...options,
  };

  if (!browser.isMobileDevice()) {
    settings.perTouch = false;
    settings.swipeThreshold = false;
    settings.dragThreshold = false;
  }

  const isClosestSlider = element.closest('.slick-slider');

  if (isClosestSlider) {
    sliderClassName = `.glide__slide:not(.slick-cloned) ${sliderClassName}`;
  }

  const glide = new Glide(sliderClassName, settings);

  if (arrows && !type) {
    glide.on(['run', 'mount.after'], () => {
      const { index } = glide;

      if (!index) {
        dom.addClass(elPrevArrow, 'slick-disabled');
      } else {
        dom.removeClass(elPrevArrow, 'slick-disabled');
      }

      if (index === sliderCount - 1) {
        dom.addClass(elNextArrow, 'slick-disabled');
      } else {
        dom.removeClass(elNextArrow, 'slick-disabled');
      }
    });
  }

  glide.mount({ EventConnection, Controls: CustomControls(hash) });
};

export default GlideSlider;
